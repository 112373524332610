import axios from 'axios'

export default {

  getAppConfig(){
    return axios.get('config/')
      .then(response => response.data)
  }

}
