import Vue from 'vue'

let constant = (Vue.prototype.$constant = {
  hasAttribute: {
    nickname: {
      value: '',
      id: 17
    },
    job: {
      value: '',
      id: 18
    },
    phone: {
      value: '',
      id: 2
    },
    company: {
      value: '',
      id: 5
    },
    website: {
      value: '',
      id: 11
    },
    presentation: {
      value: '',
      id: 6
    }
  },
  tabHasAttribute: {
    interests: {
      value: [],
      id: 14
    },
    displayedSkills: {
      value: [],
      id: 15
    },
    displayedSkillsResearched: {
      value: [],
      id: 16
    }
  }
})

export default constant
