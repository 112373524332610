import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    /* INITIALISATION DES VARIABLES DU STORE */
    template: 'T_LOGOUT',
    idRoom: 1,
    /*
    room: {
      idRoom: 1,
      firstColor: 'red',
      secondColor: 'blue'
    },
    */
    idActivity: -1,
    eventMode: false,
    bmsMode: true,
    screenMode: true,
    displayScreen: true,
    switchReactionLive: false,
    idLiveReaction: -1,
    idUser: -1,
    userSession: '',
    userIdentification: null,
    nbNewMessages: 0,
    idSlide: 1,
    indexActivityLiberty: 0,
    admin: {
      slides: {
        items: []
      },
      defaultTiming: null
    },
    activities: [],
    lang: 'fr',
    appConfig: null
    /*
    moderation: {
      tags: [],
      reactions: {
        new: [],
        noNew: []
      }
    }
    */
  },
  mutations: {
    /* MODIFICATEURS */
    SET_TEMPLATE(state, payload) {
      state.template = payload
    },
    /*
    SET_ROOM(state, payload) {
      state.room = payload
    },
    */
    SET_ID_ROOM(state, payload) {
      state.idRoom = payload
    },
    /*
    SET_FIRST_COLOR_ROOM(state, payload) {
      state.room.firstColor = payload
    },
    SET_SECOND_COLOR_ROOM(state, payload) {
      state.room.secondColor = payload
    },
    */
    SET_ID_ACTIVITY(state, payload) {
      state.idActivity = payload
    },
    SET_EVENT_MODE(state, payload) {
      state.eventMode = payload
    },
    SET_BMS_MODE(state, payload) {
      state.bmsMode = payload
    },
    SET_SCREEN_MODE(state, payload) {
      state.screenMode = payload
    },
    SET_DISPLAY_SCREEN(state, payload) {
      state.displayScreen = payload
    },
    SET_SWITCH_REACTION_LIVE(state, payload) {
      state.switchReactionLive = payload
    },
    SET_ID_LIVE_REACTION(state, payload) {
      state.idLiveReaction = payload
    },
    SET_ID_USER(state, payload) {
      state.idUser = payload
    },
    SET_USER_SESSION(state, payload) {
      state.userSession = payload
    },
    SET_USER_IDENTIFICATION(state, payload){
      state.userIdentification = payload
    },
    SET_NB_NEW_MESSAGES(state, payload) {
      state.nbNewMessages = payload
    },
    SET_ID_SLIDE(state, payload) {
      state.idSlide = payload
    },
    SET_INDEX_ACTIVITY_LIBERTY(state, payload) {
      state.indexActivityLiberty = payload
    },
    SET_ADMIN_SLIDES(state, payload) {
      state.admin.slides = payload
      console.log('slides updated')
    },
    SET_ADMIN_DEFAULT_TIMING(state, payload) {
      state.admin.defaultTiming = payload
    },
    SET_PENDING_ACTIVITY(state, payload) {
      state.pendingActivity = payload
    },
    SET_CAN_UPDATE(state, payload) {
      state.canUpdate = payload
    },
    SET_ACTIVITIES(state, payload){
      state.activities = payload
    },
    ADD_ACTIVITY(state, payload){
      state.activities.push(payload)
    },
    UPDATE_ACTIVITY(state, payload){
      state.activities[payload.activityIndex] = payload.data
    },
    DELETE_ACTIVITY(state, payload){
      state.activities.splice(payload, 1)
    },
    TURN_OFF_ALL_ACTIVITIES_ON_SMARTPHONE(state, payload){
      state.activities.forEach(activity => {
        activity.isActivatedOnSmartphone = false
      })
    },
    TURN_OFF_ALL_ACTIVITIES_ON_SCREEN(state, payload){
      state.activities.forEach(activity => {
        activity.isActivatedOnScreen = false
      })
    },
    SET_APP_CONFIG(state, payload){
      state.appConfig = payload
    }
    /*
    SET_MODERATION_REACTIONS(state, payload) {
      state.moderation.reactions = payload
    },
    SET_MODERATION_REACTIONS_NEW(state, payload) {
      state.moderation.reactions.new = payload
    },
    SET_MODERATION_REACTIONS_NO_NEW(state, payload) {
      state.moderation.reactions.noNew = payload
    },
    SET_MODERATION_REACTIONS_PROPERTIES(state, payload) {
      // on détermine si on ajoute dans new ou noNew et à quel index
      var tab = 'new'
      var indexReaction = state.moderation.reactions[tab].findIndex(
        el => el.idReaction === payload.idReaction
      )
      if (indexReaction === -1) {
        tab = 'noNew'
        indexReaction = state.moderation.reactions[tab].findIndex(
          el => el.idReaction === payload.idReaction
        )
      }
      payload.properties.forEach(
        el =>
          (state.moderation.reactions[tab][indexReaction][el.property] =
            el.value)
      )
    },
    CONCAT_END_MODERATION_REACTIONS_NO_NEW(state, payload) {
      state.moderation.reactions.noNew.concat(payload)
    },
    CONCAT_START_MODERATION_REACTIONS_NO_NEW(state, payload) {
      payload.concat(state.moderation.reactions.noNew)
    },
    SET_MODERATION_TAGS(state, payload) {
      state.moderation.tags = payload
    },
    ADD_MODERATION_TAGS(state, payload) {
      state.moderation.tags.push(payload)
    }
    */
  },
  getters: {
    /* ACCESSEURS */
    template: state => state.template,
    // room: state => state.room,
    idRoom: state => state.idRoom,
    /*
    firstColorRoom: state => state.room.firstColor,
    secondColorRoom: state => state.room.secondColor,
    */
    idActivity: state => state.idActivity,
    eventMode: state => state.eventMode,
    bmsMode: state => state.bmsMode,
    screenMode: state => state.screenMode,
    displayScreen: state => state.displayScreen,
    switchReactionLive: state => state.switchReactionLive,
    idLiveReaction: state => state.idLiveReaction,
    idUser: state => state.idUser,
    userSession: state => state.userSession,
    userIdentification: state => state.userIdentification,
    nbNewMessages: state => state.nbNewMessages,
    idSlide: state => state.idSlide,
    indexActivityLiberty: state => state.indexActivityLiberty,
    adminSlides: state => state.admin.slides,
    adminDefaultTiming: state => state.admin.defaultTiming,
    pendingActivity: state => state.pendingActivity,
    canUpdate: state => state.canUpdate,
    activities: state => state.activities,
    lang: state => state.lang,
    appConfig: state => state.appConfig
    /*
    moderationReactions: state => state.moderation.reactions,
    moderationReactionsNew: state => state.moderation.reactions.new,
    moderationReactionsNoNew: state => state.moderation.reactions.noNew,
    moderationTags: state => state.moderation.tags,
    */
    /*
    moderationReactionsAll: state => {
      return {
        new: state.moderation.reactions.new.filter(el => !el.isArchived),
        noNew: state.moderation.reactions.noNew.filter(el => !el.isArchived)
      }
    },
    moderationReactionsAddedToFeed: state => {
      return {
        new: state.moderation.reactions.new.filter(el => el.isAddedToFeed),
        noNew: state.moderation.reactions.noNew.filter(el => el.isAddedToFeed)
      }
    },
    moderationReactionsValidated: state => {
      return {
        new: state.moderation.reactions.new.filter(el => el.isValidated),
        noNew: state.moderation.reactions.noNew.filter(el => el.isValidated)
      }
    },
    moderationReactionsArchived: state => {
      return {
        new: state.moderation.reactions.new.filter(el => el.isArchived),
        noNew: state.moderation.reactions.noNew.filter(el => el.isArchived)
      }
    },
    */
    /*
    moderationReactionsFiltered: state => filters => {
      return {
        new: state.moderation.reactions.new.filter(
          el =>
            Object.keys(filters.constraints).every(
              constraint =>
                filters.constraints[constraint] === null ||
                filters.constraints[constraint] === el[constraint]
            ) &&
            (filters.tags.length === 0 ||
              el.tags.filter(elt => filters.tags.includes(elt.idTag)).length >
                0)
        ),
        // .sort((a, b) => b.nbLike - a.nbLike),
        noNew: state.moderation.reactions.noNew.filter(
          el =>
            Object.keys(filters.constraints).every(
              constraint =>
                filters.constraints[constraint] === null ||
                filters.constraints[constraint] === el[constraint]
            ) &&
            (filters.tags.length === 0 ||
              el.tags.filter(elt => filters.tags.includes(elt.idTag)).length >
                0)
        )
        // .sort((a, b) => b.nbLike - a.nbLike)
      }

    } */
  },
  actions: {
    /* METHODES DIVERSES, APPELS API, TRAITEMENTS ASYNCS ETC -> CALLBACK OK ENTRAINE MUTATION */
    setTemplate({
                  commit
                }, payload) {
      commit('SET_TEMPLATE', payload)
    },
    /*
    setRoom({ commit }, payload) {
      commit('SET_ROOM', payload)
    },
    */
    setIdRoom({
                commit
              }, payload) {
      commit('SET_ID_ROOM', payload)
    },
    setIdActivity({
                    commit
                  }, payload) {
      commit('SET_ID_ACTIVITY', payload)
    },
    setEventMode({
                   commit
                 }, payload) {
      commit('SET_EVENT_MODE', payload)
    },
    setBmsMode({
                 commit
               }, payload) {
      commit('SET_BMS_MODE', payload)
    },
    setScreenMode({
                    commit
                  }, payload) {
      commit('SET_SCREEN_MODE', payload)
    },
    setDisplayScreen({
                       commit
                     }, payload) {
      commit('SET_DISPLAY_SCREEN', payload)
    },
    setSwitchReactionLive({
                            commit
                          }, payload) {
      commit('SET_SWITCH_REACTION_LIVE', payload)
    },
    setIdLiveReaction({
                        commit
                      }, payload) {
      commit('SET_ID_LIVE_REACTION', payload)
    },
    setIdUser({
                commit
              }, payload) {
      commit('SET_ID_USER', payload)
    },
    setUserSession({
                     commit
                   }, payload) {
      commit('SET_USER_SESSION', payload)
    },
    setUserIdentification({ commit }, payload) {
      commit('SET_USER_IDENTIFICATION', payload)
    },
    setNbNewMessages({
                       commit
                     }, payload) {
      commit('SET_NB_NEW_MESSAGES', payload)
    },
    setIdSlide({
                 commit
               }, payload) {
      commit('SET_ID_SLIDE', payload)
    },
    setIndexActivityLiberty({
                        commit
                      }, payload) {
      commit('SET_INDEX_ACTIVITY_LIBERTY', payload)
    },
    nextSlide({
                state,
                commit
              }) {
      commit('SET_INDEX_ACTIVITY_LIBERTY', state.indexActivityLiberty + 1)
    },
    previousSlide({
                    state,
                    commit
                  }) {
      commit('SET_INDEX_ACTIVITY_LIBERTY', state.indexActivityLiberty - 1)
    },
    setAdminSlides({
                     commit
                   }, payload) {
      commit('SET_ADMIN_SLIDES', payload)
    },
    setAdminDefaultTiming({
                            commit
                          }, payload) {
      commit('SET_ADMIN_DEFAULT_TIMING', payload)
    },
    updateSlideOrder({ commit, state }, payload){
      console.log('starting slide update')
      const oldOrder = [...state.admin.slides]
      const itemToMove = state.admin.slides[payload.itemToMove]
      console.log(payload.itemToMove + '/' + payload.newIndex)
      const newOrder = []
      console.log('old', oldOrder)

      for(let i=0; i <oldOrder.length; i++){
        if(i !== Number(payload.itemToMove)){
          if(i !== Number(payload.newIndex)){
            newOrder.push(oldOrder[i])
          }
          else {
            newOrder.push(itemToMove)
            newOrder.push(oldOrder[i])
          }
        }
      }
      console.log('new', newOrder)
      /* if(payload.itemToMove < payload.newIndex){
        for(let entry of oldOrder.slice(0, payload.itemToMove)){
          newOrder.push(entry)
        }
        for(let entry of oldOrder.slice(payload.itemToMove + 1, payload.newIndex + 2)){
          newOrder.push(entry)
        }
        newOrder.push(itemToMove)
        if(payload.newIndex < oldOrder.length){
          for(let entry of oldOrder.slice(payload.newIndex + 2, oldOrder.length + 1)){
            newOrder.push(entry)
          }
        }
      }
      else if (payload.itemToMove > payload.newIndex) {
        if(payload.newIndex === 0){
          newOrder.push(itemToMove)
          oldOrder.splice(payload.itemToMove, 1)
          for(let entry of oldOrder){
            newOrder.push(entry)
          }
        }
        else {
          for(let entry of oldOrder.slice(0, payload.newIndex)){
            newOrder.push(entry)
          }
          newOrder.push(itemToMove)
          oldOrder.splice(payload.itemToMove, 1)
          for(let entry of oldOrder.slice(payload.newIndex + 1, oldOrder.length + 1)){
            newOrder.push(entry)
          }
        }
      } */
      console.log(newOrder)
      commit('SET_ADMIN_SLIDES', newOrder)
    },
    pendingActivity({ commit }, payload){
      commit('SET_PENDING_ACTIVITY', payload)
    },
    canUpdate({ commit }, payload){
      commit('SET_CAN_UPDATE', payload)
    },
    setActivities({ commit }, payload){
      commit('SET_ACTIVITIES', payload)
    },
    addActivity({ commit }, payload){
      commit('ADD_ACTIVITY', payload)
    },
    updateActivity({ commit }, payload){
      commit('UPDATE_ACTIVITY', payload)
    },
    deleteActivity({ commit }, payload){
      commit('DELETE_ACTIVITY', payload)
    },
    turnOffActivitiesOnSmartphone({ commit }, payload){
      commit('TURN_OFF_ALL_ACTIVITIES_ON_SMARTPHONE')
    },
    turnOffActivitiesOnScreen({ commit }, payload){
      commit('TURN_OFF_ALL_ACTIVITIES_ON_SCREEN')
    },
    fetchAppConfig({ commit }, payload){
      commit('SET_APP_CONFIG', payload)
    }
    /*
    setModerationReactions({ commit }, payload) {
      commit('SET_MODERATION_REACTIONS', payload)
    },
    setModerationReactionsNew({ commit }, payload) {
      commit('SET_MODERATION_REACTIONS_NEW', payload)
    },
    setModerationReactionsNoNew({ commit }, payload) {
      commit('SET_MODERATION_REACTIONS_NO_NEW', payload)
    },
    setModerationReactionsProperties({ commit }, payload) {
      commit('SET_MODERATION_REACTIONS_PROPERTIES', payload)
    },
    concatEndModerationReactionsNoNew({ commit }, payload) {
      commit('CONCAT_END_MODERATION_REACTIONS_NO_NEW', payload)
    },
    concatStartModerationReactionsNoNew({ commit }, payload) {
      commit('CONCAT_START_MODERATION_REACTIONS_NO_NEW', payload)
    },
    setModerationTags({ commit }, payload) {
      commit('SET_MODERATION_TAGS', payload)
    },
    addModerationTags({ commit }, payload) {
      commit('ADD_MODERATION_TAGS', payload)
    }
    */
  },
  /* MODE STRICT UNIQUEMENT EN DEV -> DEBOGAGE */
  strict: process.env.NODE_ENV !== 'production'
})

export default store
