import axios from 'axios'

export default {
  addMedia(idRoom, filename, file) {
    var params = new FormData()
    params.append('image', file, { type: 'png' })
    return axios
      .post('room/upload/' + idRoom + '/' + filename, params, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  deleteMedia(idRoom, params) {
    return axios.post('room/delete-media/' + idRoom, params).then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  updateRoom(params) {
    return axios.post('room/update', params).then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  /* Return les routes des différentes rooms -> pour surcharger le routeur */
  getRoutesOfRooms() {
    return axios.get('room/all/routes').then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  /* Return les rooms */
  getRooms(params) {
    return axios.get('room/all/' + JSON.stringify(params)).then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  /* Return une room */
  getRoom(params) {
    return axios.get('room/select/' + JSON.stringify(params)).then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  /* Return le style d'une room */
  getRoomStyle(params) {
    return axios.get('room/style/' + JSON.stringify(params)).then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
        return error.response.status
      }
    )
  },

  /* Create Room */
  createRoom(params) {
    return axios.post('room/new', params).then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  /* Archive Room */
  archiveRoom(params) {
    return axios.post('room/archive', params).then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  }
}
