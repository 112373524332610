import Vue from 'vue'

let filters = (Vue.prototype.$filters = {
  nomPropre(value) {
    if (value != null) {
      value = value.toLowerCase()
      var sentence = value.split(' ')
      for (var word in sentence) {
        sentence[word] =
          sentence[word].substr(0, 1).toUpperCase() +
          sentence[word].substr(1, sentence[word].length)
      }
      return sentence.join(' ')
    }
    return value
  },
  truncateString(value, length = 200) {
    return value.substring(0, length)
  },
  toJson(data) {
    if (typeof data === 'string') {
      return JSON.parse(data)
    }
    return data
  }
})

export default filters
